
const WhiteComponent = () => {
    return(
        <>
            <div style={{width: "100vw", height: "80vh", position: "relative"}}>
                <div className="center">
                    <h1 className="text-center" style={{fontSize: "6rem", color: "rgba(255, 255, 255, 0.8)"}}>
                        COMMING SOON ...
                    </h1>
                </div>
            </div>
        </>
    )
}

export default WhiteComponent;